<template>
    <div class="broadcast">
        <div class="title">发车播报设置</div>
        <el-form :model="dialogForm" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
             <el-form-item label="播报开启" prop="broadcastSwitch">
                <el-radio-group v-model="dialogForm.broadcastSwitch">
                    <el-radio label="1">开启</el-radio>
                    <el-radio label="0">关闭</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="播报线路" prop="routeIds">
                <el-select v-model="dialogForm.routeIds" multiple placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.routeId"
                    :label="item.name"
                    :value="item.routeId">
                    </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="播报时间" prop="timeData">
                发车前<el-input type="number" v-model="dialogForm.advance"></el-input>分钟开始播报，接下来每隔<el-input type="number" v-model="dialogForm.interval"></el-input>分钟进行下一次的播报。
            </el-form-item>
        </el-form>
        <div class="btn_box">
            <el-button @click="cancel" v-if="btnexist('determine')">重 置</el-button>
            <el-button type="primary" @click="save" v-if="btnexist('list')">确 定</el-button>
        </div>
    </div>
</template>
<script>
const rules={
    broadcastSwitch:[{required: true, message: '请设置', trigger: 'change'}],
    // routeIds:[{required: true, message: '请选择线路', trigger: 'change'}],
    timeData:[{required: true, message: '请输入大于 0 的整数', trigger: 'change'}],
}
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data(){
        return {
            dialogForm:{
                broadcastId:'',
                broadcastSwitch:'',
                // routeIds:[],
                advance:'',
                interval:'',
                timeData:'',
            },
            options: [],
            formLabelWidth: '150px',
            rules:null,

            btnRoute:'/admin/systemSettings/broadcast',
        }
    },
    mixins:[btnMixins],
    created(){
        this.rules = rules
    },
    watch:{
        'dialogForm.broadcastSwitch':{
            handler:function(val){
                if(val==1){
                    this.rules = rules
                }else{
                    this.rules = {
                        broadcastSwitch: rules.broadcastSwitch
                    }
                }
                this.$nextTick(this.$refs.dialogForm.clearValidate)
            },
            immediate:true,
        },
        'dialogForm.advance':function(){
            function isInt(num){
                return num % 1 == 0
            }
            let formData = this.dialogForm
            if(formData.advance&&formData.interval){
                if(isInt(formData.advance) && isInt(formData.interval)){
                    formData.timeData = true
                }else{
                    formData.timeData = ''
                }
            }else{
                formData.timeData = ''
            }
        },
        'dialogForm.interval':function(){
            function isInt(num){
                return num % 1 == 0
            }
            let formData = this.dialogForm
            if(formData.advance&&formData.interval){
                if(isInt(formData.advance) && isInt(formData.interval)){
                    formData.timeData = true
                }else{
                    formData.timeData = ''
                }
            }else{
                formData.timeData = ''
            }
        }
    },
    activated(){
        this.getline()
        this.getList()
    },
    methods:{
        getList() {
            let url = '/baseinfobroadcast/queryCurrentSet'
            this.$http.post(url).then(res => {
                console.log(res)
                this.dialogForm.broadcastId = res.detail.broadcastId
                this.dialogForm.broadcastSwitch = res.detail.broadcastSwitch
                // this.dialogForm.routeIds = res.detail.routeIds
                this.dialogForm.advance = res.detail.advance
                this.dialogForm.interval = res.detail.interval
            })
        },

        getline(){
            let url = '/baseinfobroadcast/queryRouteList'
            this.$http.post(url).then(res => {
                this.options = res.detail
            })
        },
        cancel(){
            let url = '/baseinfobroadcast/reset'
            let option = {
                broadcastId:this.dialogForm.broadcastId,
            }
            // console.log(option)
            this.$http.post(url,option).then(res => {
                this.getList()
                this.$message({
                    type: 'success',
                    message: res.msg,
                })
            })
        },
        save(){
            let option = {
                ...this.dialogForm,
            }
            let url = '/baseinfobroadcast/save'
            // console.log(option)
            this.$refs.dialogForm.validate((valid) => {
                if(valid){
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                        })
                    })
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped="broadcast">
.broadcast{
    .title{
        padding: 10px 0;
        font-size: 18px;
    }
    .btn_box{
        padding-left: 250px;
    }
}
</style>
<style lang="scss">
.broadcast{
    .el-select{
        .el-input{
            width: 100%;
            display:block;
            padding:0;
        }
    }
    .el-input{
        display:inline-block;
        width: 150px;
        padding:0 5px;
    }
}
</style>



